import React from "react"

const Header = props => {
  return (
    <div className="2xl:px-[160px] sm:px-[50px] px-[10px] md:mt-10 mt-4 ">
      <h1 className=" md:text-2xl text-xl font-medium">{props.heading}</h1>
      <h2 className="tracking-wide md:text-xl text-lg">{props.subHeading}</h2>
      <p className="tracking-wide text-[16px]">{props.description}</p>
    </div>
  )
}

export default Header
